div.attributeEditList {
  display: inline;
}
input.formAttribute {
  width: 50%;
  display: inline;
}
body.dragging,
body.dragging * {
  cursor: move !important;
}
.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}
ol.example li.placeholder {
  position: relative;
  /** More li styles **/
}
ol.example li.placeholder:before {
  position: absolute;
  /** Define arrowhead **/
}
.sorted_table tr.placeholder {
  display: block;
  background: red;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}
/* line 103, /Users/jonasvonandrian/jquery-sortable/source/css/application.css.sass */
.sorted_table tr.placeholder:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: red;
  margin-top: -5px;
  left: -5px;
  border-right: none;
}
.cmm-checkbox-padding {
  padding-top: 8px;
}
.cmm-input-with-button {
  width: 74%;
  display: inline;
}
.cmm-input-with-button-icon {
  right: 176px !important;
}
.previewmail {
  font-family: sans-serif, Arial, Verdana, "Trebuchet MS";
  font-size: 12px;
  /* Text color */
  color: #333;
}
.fat {
  font-weight: bold;
}
.no-list-style {
  list-style: none;
}
.cmm-topbar-logout {
  padding-top: 3px;
}
select.paymentstatusSelect {
  width: 50% !important;
}
select.paymentmethodSelect {
  width: 50% !important;
}
li.clickable:hover {
  text-decoration: underline;
  cursor: help;
}
li.timeline-detail-item {
  border-top: none !important;
}
#ui-datepicker-div,
.datecontrol,
.ui-datepicker {
  z-index: 100000 !important;
}
/*dropzone demo css*/
.dz-demo .dz-message {
  opacity: 0 !important;
}
.dropzone .dz-preview.example-preview .dz-success-mark,
.dropzone-previews .dz-preview.example-preview .dz-success-mark {
  opacity: 1;
}
.dropzone .dz-preview.example-preview .dz-error-mark,
.dropzone-previews .dz-preview.example-preview .dz-error-mark {
  opacity: 0;
}
